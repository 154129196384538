.App {
  text-align: center;
}

.header-name {
padding: 60px;
font-family: "Righteous";
font-size: 80px;
color: white;
text-align: center;
}

.header {
  background-color: black;
  margin: 30px

}

.testimonial-body {
  text-align: left !important;
}

.nav-list {
display: inline-flex;
justify-content: left !important;

}
.nav-option {
  font-family: "Raleway" !important;
  color: #fff;
  text-decoration: none;
  text-align: center;
  /* margin: 0 0.5rem; */

  &:hover {
    text-decoration: underline;
  }

  
  @media (min-width: 768px) {
    margin: 0 1rem; 
}
}

.contact-button {
  color: rgb(95, 17, 221);
  font-weight: 100 !important;
  font-size: 15px;

  font-family: "Raleway";
 position: relative;
 margin-left: 10px;
 
}

.services-option {
  background-color: black;
  color: white;
  border: none;
}

.image-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: black;
  padding-bottom: 160px;
  margin-left: 30px;
  margin-right: 30px;
  
}

.image-container {
  text-align: center; /* Center the text above the image */
}

.image-text {
  margin: 10 0 0 10px; /* Add some space between the text and the image */
  font-size: 26px; /* Adjust font size as needed */
  color: aliceblue;
  font-family: "Righteous";
}

.image-row img {
  width: 90%;
  max-width: 400px;
}

@media (min-width: 768px) {
  .image-row {
    flex-direction: row;
    justify-content: space-around;
  }
}

.Contact {
  background: black;
  color: white;
}

.footer {
  margin-left: 30px;
  margin-right: 30px;
}


.component-title {
  text-align: center;
  font-family: "Raleway";
  font-size: 60px !important;
  padding-top: 60px;
  padding-bottom: 50px;

  }
  
  .testimonial-container {
    padding-right: 80px;
  }

  .component-body {
    font-family: "Raleway";
    text-align: left;
    padding-left: 65px;
    padding-top: 40px;
    flex: 1;
    
  }

  .dropdown {
    background: black !important
  }

  .drop-link {
    position: relative;
    bottom: 1px;
    
  }

  a, a:visited {
    text-decoration: none;
  }
  
  /* Or for React Router Link elements specifically, if they have a specific class */
  .link, .link:visited {
    text-decoration: none !important;
  }

.home-link {
  text-decoration: none !important;
}

.footer-style {
  background: white !important;
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: "Raleway";
  color: black;
}

.band-background {
  width: 100%;
  height: 650px;
  /* background-image: url('./img/bandpage.jpeg'); Path to your image */
  background-color: rgb(0, 0, 0);
  background-size: cover; 
  /* background-position: left;  */
  color: white !important;
}

.pic-format {

  width: 100%; /* Make image responsive within its container */
  max-width: 500px; /* Adjust based on your needs */
  height: auto; 
}

.side-by-side {
  display: flex; /* Use flexbox to layout children side by side */
  align-items: center; /* Align children vertically in the center */
  justify-content: center; /* Center children horizontally */
  gap: 20px; /* Add some space between the image and text */
  padding-left: 40px ;
}
.container {
  display: flex;
  flex-direction: column; /* Stacks children for mobile */
  align-items: center;
  text-align: center;
}

.responsive-img {
  width: 90%; /* Adjust based on your preference */
  height: auto;
  margin-bottom: 20px; /* Space between image and text on mobile */
}

.text-content {
  width: 90%; /* Adjust text container width as needed */
}

/* Larger screens */
@media (min-width: 768px) { /* Adjust this breakpoint as needed */
  .container {
    flex-direction: row; /* Side-by-side layout for desktop */
    justify-content: center;
    text-align: left;
    gap: 30px;
   
  }

  .responsive-img, .text-content {
    width: auto; /* Reset widths for desktop */
    flex: 1; /* Allows children to grow and share the available space */
    margin-bottom: 0; /* Reset margin for desktop */
    font-family: "Raleway";

  }

  .responsive-img {
    max-width: 50%; /* Adjust the image max-width as needed */
  }

  .text-content {
    max-width: 50%; /* Adjust the text content max-width as needed */
  }
}

/* .karaoke-background {
  width: 100%;
  height: 600px;
  background-image: url('./img/karaokepage.jpeg'); 
  background-size: cover; 
  background-position: center; 
  color: white !important;
  position: relative;
  z-index: 1;
} 



.dj-background {
  width: 100%;
  height: 600px;
  background-image: url('./img/DJpage.jpg'); 
  background-size: cover; 
  background-position: center -260px; 
  color: white !important;

} */